<template>
  <div>
    <h2>Détail du prélèvement {{ referencePrelevementDetails ? ': ' + referencePrelevementDetails : "" }} {{ ' du ' + datePrelevementPrelevementDetails }}</h2>

    <vue-good-table
      :columns="columnsDetailsPrelevement"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: pageLength,
        rowsPerPageLabel: '',
        dropdownAllowAll: false,
        perPageDropdownEnabled: false,
        nextLabel: 'Suivant',
        prevLabel: 'Précédent',
        ofLabel: 'de',
        infoFn: params => ``
      }"
      :rows="detailsPrelevement"
      :rtl="direction"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'lignes sélectionnées',
        clearSelectionText: 'Effacer la sélection',
        disableSelectInfo: false,
        selectAllByGroup: false
      }"
      style-class="vgt-table condensed"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'actions' && typeInterface==='Prelevement Executes'">
          <span>
            <b-dropdown
              dropright
              no-caret
              toggle-class="text-decoration-none p-0"
              variant="link"
            >
              <template v-slot:button-content>
                <feather-icon
                  class="text-body align-middle mr-25"
                  icon="MoreVerticalIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item @click="displayDocument(props.row.document_id)">
                <feather-icon
                  class="mr-50"
                  icon="SearchIcon"
                />
                <span>Visualiser Facture</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="props.row.has_avoir" @click="displayDocument(props.row.document_id_avoir)">
                <feather-icon
                  class="mr-50"
                  icon="SearchIcon"
                />
                <span>Visualiser Avoir</span>
              </b-dropdown-item>
              <b-dropdown-divider v-if="!props.row.has_avoir" />
              <b-dropdown-item v-if="!props.row.has_avoir" @click="annulerFacture(props.row.id, props.row.facture_id ,true)" variant="danger">
                <feather-icon
                  class="mr-50"
                  icon="XIcon"
                />
                <span>Annuler Facture (avec remboursement)</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="!props.row.has_avoir" @click="annulerFacture(props.row.id, props.row.facture_id ,false)" variant="danger">
                <feather-icon
                  class="mr-50"
                  icon="XIcon"
                />
                <span>Annuler Facture (sans remboursement)</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-if="props.column.field === 'has_avoir_dot' && typeInterface==='Prelevement Executes'">
          <span :class="props.row.has_avoir ? 'red-dot' : 'green-dot'"
                v-b-tooltip.hover.top="props.row.has_avoir ? 'Cette facture comporte un avoir.' : 'Cette facture ne comporte pas d\'avoir.'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'" />
        </span>
        <span v-if="props.column.field === 'rejet'">
          <b-badge :variant="sourceVariant(props.row.rejet)">
            {{ props.row.rejet }}
          </b-badge>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <b-row class="align-items-center mt-2">
          <b-col>
            <b-pagination
              :per-page="pageLength"
              :total-rows="props.total"
              :value="1"
              align="right"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="value => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </template>
      <div
        slot="emptystate"
        class="text-center align-middle"
      >
        <span>Aucune donn&eacute;e disponible dans le tableau</span>
      </div>
    </vue-good-table>

    <b-modal
      id="modal-display-document"
      ref="modal-display-document"
      :size="document.extensionDocument === 'PDF' ? 'xl' : 'sm'"
      :title="document.name"
      cancel-title="Fermer"
      cancel-variant="outline-secondary"
      ok-title="Télécharger"
      @ok="downloadDocument(document.base64, document.nameToDownload)"
    >
      <form
        ref="form"
        :style="{height : document.extensionDocument === 'PDF' ? '80vh' : 'auto', 'overflow': 'hidden'}"
      >
        <b-img
          v-if="document.extensionDocument === 'IMAGE'"
          :alt="document.name"
          :src="document.base64"
          fluid
        />
        <iframe
          v-else-if="document.extensionDocument === 'PDF'"
          :src="document.base64"
          height="100%"
          width="100%"
          :style="hideToolbar()"
        />
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BDropdown,
  BDropdownItem,
  BPagination,
  BRow,
  BImg,
  BModal,
  VBToggle,
  VBTooltip,
  BBadge,
  BDropdownDivider,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BCol,
    BDropdown,
    BDropdownItem,
    BPagination,
    BRow,
    BImg,
    BModal,
    VueGoodTable,
    BBadge,
    BDropdownDivider
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  props: {
    propParentPrelevementDetails: {
      type: Array,
      required: false,
    },
    referencePrelevementDetails: {
      type: String,
      required: false,
    },
    datePrelevementPrelevementDetails: {
      type: String,
      required: false,
    },
    typeInterface: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      detailsPrelevement: [],
      pageLength: 10,
      dir: false,
      columnsDetailsPrelevement: [],
      document: {
        base64: null,
        name: null,
        nameToDownload: null,
        extensionDocument: null,
      },
    }
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    typeInterface: {
      immediate: true,
      handler(val) {
        if (val === 'Prelevement Executes') {
          this.columnsDetailsPrelevement = [
            {
              label: '',
              field: 'has_avoir_dot',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'ID',
              field: 'id',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'Début période',
              field: 'debut_periode',
              type: 'date',
              dateInputFormat: 'dd/MM/yyyy',
              dateOutputFormat: 'dd/MM/yyyy',
              formatFn(value) { return value != null ? value : null },
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'Fin période',
              field: 'fin_periode',
              type: 'date',
              dateInputFormat: 'dd/MM/yyyy',
              dateOutputFormat: 'dd/MM/yyyy',
              sortable: false,
              formatFn(value) { return value != null ? value : null },
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'Dénomination commerciale',
              field: 'denomination_commerciale',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'N° ORIAS',
              field: 'numero_orias',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'N° Facture',
              field: 'n_facture',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'RIB',
              field: 'rib',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'Montant',
              field: 'montant',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'Rejet',
              field: 'rejet',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              field: 'actions',
              label: 'Actions',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 py-1 px-2 align-middle',
            },
          ]
        } else if (val === 'Prochain Prelevement') {
          this.columnsDetailsPrelevement = [
            {
              label: 'ID',
              field: 'id',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'Début période',
              field: 'debut_periode',
              type: 'date',
              dateInputFormat: 'dd/MM/yyyy',
              dateOutputFormat: 'dd/MM/yyyy',
              formatFn(value) { return value != null ? value : null },
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'Fin période',
              field: 'fin_periode',
              type: 'date',
              dateInputFormat: 'dd/MM/yyyy',
              dateOutputFormat: 'dd/MM/yyyy',
              formatFn(value) { return value != null ? value : null },
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'Dénomination commerciale',
              field: 'denomination_commerciale',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'N° ORIAS',
              field: 'numero_orias',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'N° Facture',
              field: 'n_facture',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'RIB',
              field: 'rib',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'Montant',
              field: 'montant',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'Rejet',
              field: 'rejet',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            }
          ]
        } else if (val === 'Rejet Prelevement') {
          this.columnsDetailsPrelevement = [
            {
              label: 'ID',
              field: 'id',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'Date rejet',
              field: 'date',
              type: 'date',
              dateInputFormat: 'dd/MM/yyyy',
              dateOutputFormat: 'dd/MM/yyyy',
              formatFn(value) { return value != null ? value : null },
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'Ref Mandat',
              field: 'ref_mandat',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'Code motif',
              field: 'code_motif',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'Motif',
              field: 'motif',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'Date de prélèvement',
              field: 'date_prelevement',
              type: 'date',
              dateInputFormat: 'dd/MM/yyyy',
              dateOutputFormat: 'dd/MM/yyyy',
              formatFn(value) { return value != null ? value : null },
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'Courtier',
              field: 'denomination_commerciale',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            },
            {
              label: 'Montant',
              field: 'montant',
              sortable: false,
              thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
              tdClass: 'text-center m-0 p-2 align-middle h6',
            }
          ]
        }
      }
    },
    propParentPrelevementDetails: {
      immediate: true,
      deep: true,
      handler(val) {
        this.detailsPrelevement = val
      },
    },
  },
  methods: {
    // document
    downloadDocument(base64, name) {
      if (base64) {
        const a = document.createElement('a')
        a.href = base64
        a.download = name
        a.click()
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
    clearDocumentDisplay() {
      this.document.base64 = null
      this.document.name = null
      this.document.nameToDownload = null
      this.document.extensionDocument = null
    },
    displayDocument(id) {
      this.emitLoadingOverLay(true)
      this.$http
        .get(`/document/generate_base64_for_document/${id}`)
        .then(res => {
          if (res.data.success) {
            this.clearDocumentDisplay()
            if (res.data.data.isDownload) {
              this.downloadDocument(res.data.data.base64, res.data.data.nameToDownload)
            } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
              this.document.base64 = res.data.data.base64
              this.document.name = res.data.data.nameToDownload
              this.document.nameToDownload = res.data.data.nameToDownload
              this.document.extensionDocument = res.data.data.extensionDocument
              this.$refs['modal-display-document'].show()
            } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
              this.document.base64 = res.data.data.base64
              this.document.name = res.data.data.nameToDownload
              this.document.nameToDownload = res.data.data.nameToDownload
              this.document.extensionDocument = res.data.data.extensionDocument
              this.$refs['modal-display-document'].show()
              // const pdfWindow = window.open('')
              // pdfWindow.document.write(`<iframe width='100%' height='100%' src='${res.data.data.base64}' />`)
              // this.downloadDocument(res.data.data.base64, name)
            }
          } else {
            this.clearDocumentDisplay()
            this.messageToast(res.data.message, 'Erreur', 'error')
          }
        })
        .catch(err => {
          this.clearDocumentDisplay()
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
        })
        .finally(() => {
          this.emitLoadingOverLay(false)
        })
    },

    // Annulation facture
    annulerFacture(idPrelevementEcheance, idFacture, avecRemboursement = false) {
      this.$swal({
        input: 'select',
        inputValue: 'SUITE_A_LA_RESILIATION',
        title: 'Raison de l’annulation',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Valider',
        cancelButtonText: 'Annuler',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,

        inputOptions: {
          SUITE_A_LA_RESILIATION: 'suite à la résiliation',
          SUITE_A_ERREUR_DE_FACTURATION: 'suite à erreur de facturation'
        },
      }).then(result => {
        if (result.value) {
          this.emitLoadingOverLay(true)
          const data = {
            idPrelevementEcheance,
            idFacture,
            avecRemboursement,
            raisonAnnulation: result.value,
          }
          this.$http
            .post('/prelevement/annulerFacture', data)
            .then(res => {
              if (res.data.success) {
                this.messageToast(res.data.message, 'Succès', 'success')
              }
            })
            .catch(err => {
              this.clearDocumentDisplay()
              this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
              console.error(err)
            })
            .finally(() => {
              this.emitLoadingOverLay(false)
            })
        }
      })
    },

    emitLoadingOverLay(boolVal) {
      this.$emit('emit-loading-over-lay', boolVal)
    }
  },
}
</script>

<style>
.swal2-select {
  width: 100%;
}
.row-color-red {
  background-color: #ff000042;
}

.green-dot {
  height: 20px;
  width: 20px;
  background-color: #24a124;
  border-radius: 50%;
  display: inline-block;
}

.red-dot {
  height: 20px;
  width: 20px;
  background-color: #d41212;
  border-radius: 50%;
  display: inline-block;
}
</style>
