<template>
  <div>
    <vue-good-table
        :columns="columnsProchainPrelevement"
        :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: pageLength,
        rowsPerPageLabel: '',
        dropdownAllowAll: false,
        perPageDropdownEnabled: false,
        nextLabel: 'Suivant',
        prevLabel: 'Précédent',
        ofLabel: 'de',
        infoFn: params => ``
      }"
        :rows="prochainPrelevement"
        :rtl="direction"
        :select-options="{
              enabled: false,
              selectOnCheckboxOnly: true,
              selectionInfoClass: 'custom-class',
              selectionText: 'lignes sélectionnées',
              clearSelectionText: 'Effacer la sélection',
              disableSelectInfo: false,
              selectAllByGroup: false
            }"
        style-class="vgt-table condensed"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'actions'">
          <span>
            <b-dropdown dropright no-caret toggle-class="text-decoration-none p-0" variant="link">
              <template v-slot:button-content>
                <feather-icon class="text-body align-middle mr-25" icon="MoreVerticalIcon" size="16"/>
              </template>
              <b-dropdown-item
                  @click="$emit('visualiser-from-prochain-prelevement', {id: props.row.id, reference: props.row.reference, date_prelevement: props.row.date_prelevement, typeInterface: 'Prochain Prelevement'})">
                <feather-icon class="mr-50" icon="SearchIcon"/>
                <span>Visualiser</span>
              </b-dropdown-item>
              <b-dropdown-item @click="donwloadAsExcel(props.row.id, 'Prochain Prelevement')">
                <feather-icon class="mr-50" icon="DownloadCloudIcon"/>
                <span>Télécharger</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <b-row class="mt-2 align-items-center">
          <b-col>
            <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                align="right"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="value => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </template>
      <div slot="emptystate" class="text-center align-middle">
        <span>Aucune donn&eacute;e disponible dans le tableau</span>
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BCol,
  BDropdown,
  BDropdownItem,
  BPagination,
  BRow,
  VBToggle,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BCol,
    BDropdown,
    BDropdownItem,
    BPagination,
    BRow,
    VueGoodTable
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  props: {
    propChildProchainPrelevement: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      prochainPrelevement: [],
      pageLength: 10,
      dir: false,
      columnsProchainPrelevement: [
        // {
        //   label: 'Référence',
        //   field: 'reference',
        //   sortable: false,
        //   thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
        //   tdClass: 'text-center m-0 p-2 align-middle h6'
        // },
        {
          label: 'Date prélèvement',
          field: 'date_prelevement',
          type: 'date',
          dateInputFormat: 'dd/MM/yyyy',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-2 align-middle h6'
        },
        {
          label: 'Nbr de flux',
          field: 'nbr_flux',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-2 align-middle h6'
        },
        {
          label: 'Total flux',
          field: 'total_flux',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-2 align-middle h6'
        },
        {
          label: 'Date de création',
          field: 'date_creation',
          type: 'date',
          dateInputFormat: 'dd/MM/yyyy',
          dateOutputFormat: 'dd/MM/yyyy',
          formatFn(value) { return value != null ? value : null },
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-2 align-middle h6'
        },
        {
          field: 'actions',
          label: 'Actions',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 py-1 px-2 align-middle'
        }
      ],
    }
  },
  watch: {
    propChildProchainPrelevement: {
      immediate: true,
      deep: true,
      handler(val) {
        this.prochainPrelevement = val
      }
    },
  },
  methods: {
    downloadDocument(base64, name) {
      if (base64) {
        // Retrieve the base64 encoded Excel file
        // Convert the base64 string to a Blob object
        const blob = this.base64ToBlob(base64);
        // Create a download link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        // Set the file name for the download
        link.download = name;
        // Programmatically trigger the download
        link.click();
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
    base64ToBlob(base64String) {
      // Extract the content type and base64 data from the string
      // const parts = base64String.split(';base64,');
      const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      // const b64Data = parts[1];

      // Convert the base64 data to a Blob object
      const byteCharacters = atob(base64String);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: contentType });
    },
    donwloadAsExcel(id, typeInterface) {
      this.$http
          .get(`/prelevement/donwloadAsExcel/${id}/${typeInterface}`)
          .then(res => {
            this.downloadDocument(res.data.data.base64, res.data.data.name)
          })
          .catch(err => {
            this.messageToast(err.response.data.errors[0], 'Erreur', 'danger')
          })
          .finally(() => {
            this.showLoading = false
          })
    }
  }
}
</script>

<style scoped>

</style>