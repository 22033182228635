<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap">
      <div class="d-flex align-items-center mb-1 mt-1">
        <span class="h1 text-primary font-weight-bolder text-uppercase ml-1 my-0">Gestion</span>
      </div>
    </div>
    <b-card>
      <b-overlay :show="showLoading" no-wrap />
      <div class="d-flex flex-column bd-highlight mb-3">
        <div class="p-2 bd-highlight">
          <prelevement-parent
              :prop-parent-prelevement-executes="prelevementExecutes"
              :prop-parent-prochain-prelevement="prochainPrelevement"
              :prop-parent-rejet-prelevement="rejetPrelevement"
              @visualiser-from-prelevement-executes="visualiserFromPrelevementExecutes"
              @visualiser-from-prochain-prelevement="visualiserFromProchainPrelevement"
              @visualiser-from-rejet-prelevement="visualiserFromRejetPrelevement"
              @handle-tab-change="handleTabChange"
          />
        </div>
        <div class="p-2 bd-highlight" v-if="detailsPrelevement.length > 0">
          <details-prelevement-parent
              :prop-parent-prelevement-details="detailsPrelevement"
              :reference-prelevement-details="reference"
              :type-interface="typeInterface"
              :date-prelevement-prelevement-details="date_prelevement"
              @emit-loading-over-lay="emitLoadingOverLay"
          />
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BOverlay } from 'bootstrap-vue'
import prelevementParent from './gestion-prelevement/prelevementParent.vue'
import detailsPrelevementParent from './detail-prelevement/detailsPrelevementParent.vue'

export default {
  components: {
    BCard,
    BOverlay,
    prelevementParent,
    detailsPrelevementParent,
  },
  data() {
    return {
      showLoading: false,
      reference: null,
      date_prelevement: null,
      typeInterface: 'Prelevement Executes',
      prelevementExecutes: [],
      prochainPrelevement: [],
      rejetPrelevement: [],
      detailsPrelevement: []
    }
  },
  mounted() {
    this.fetchPrelevementExecutes()
    this.fetchProchainPrelevement()
    this.fetchRejetPrelevement()
  },
  methods: {
    handleTabChange(isClear) {
      if (isClear) {
        this.detailsPrelevement = []
        this.reference = null
        this.date_prelevement = null
        this.typeInterface = 'Prelevement Executes'
      }
    },
    emitLoadingOverLay(boolVal) {
      this.showLoading = boolVal
    },
    // Request to backEnd
    fetchPrelevementExecutes() {
      this.showLoading = true
      this.$http
          .get('/prelevement/fetchPrelevementExecutes')
          .then(res => {
            if (res.data.success) {
              this.prelevementExecutes = res.data.data
            }
          })
          .catch(err => {
            this.prelevementExecutes = []
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          })
          .finally(() => {
            this.showLoading = false
          })
    },
    fetchProchainPrelevement() {
      this.showLoading = true
      this.$http
          .get('/prelevement/fetchProchainPrelevement')
          .then(res => {
            if (res.data.success) {
              this.prochainPrelevement = [res.data.data.prelevement]

              this.reference = res.data.data.prelevement.reference
              this.date_prelevement = res.data.data.prelevement.date_prelevement
              this.detailsPrelevement = res.data.data.echeance
              this.typeInterface = 'Prochain Prelevement'
            }
          })
          .catch(err => {
            this.prochainPrelevement = []
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          })
          .finally(() => {
            this.showLoading = false
          })
    },
    fetchRejetPrelevement() {
      this.showLoading = true
      this.$http
          .get('/prelevement/fetchRejetPrelevement')
          .then(res => {
            if (res.data.success) {
              this.rejetPrelevement = res.data.data
            }
          })
          .catch(err => {
            this.rejetPrelevement = []
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          })
          .finally(() => {
            this.showLoading = false
          })
    },
    visualiserFromProchainPrelevement() {
      this.fetchProchainPrelevement()
    },
    visualiserFromRejetPrelevement(data) {
      this.showLoading = true
      this.$http
          .get(`/prelevement/fetchDetailsRejetPrelevement/${data.id}`)
          .then(res => {
            if (res.data.success) {
              this.reference = null
              this.date_prelevement = data.date_prelevement
              this.detailsPrelevement = res.data.data
              this.typeInterface = 'Rejet Prelevement'
            }
          })
          .catch(err => {
            this.detailsPrelevement = []
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          })
          .finally(() => {
            this.showLoading = false
          })
    },
    visualiserFromPrelevementExecutes(data) {
      this.showLoading = true
      this.$http
          .get(`/prelevement/fetchDetailsPrelevement/${data.id}`)
          .then(res => {
            if (res.data.success) {
              this.reference = data.reference
              this.date_prelevement = data.date_prelevement
              this.detailsPrelevement = res.data.data
              this.typeInterface = 'Prelevement Executes'
            }
          })
          .catch(err => {
            this.detailsPrelevement = []
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          })
          .finally(() => {
            this.showLoading = false
          })
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style scoped>
  .vgt-responsive {
    min-height: 100% !important;
  }
</style>
