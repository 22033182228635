<template>
  <div>
    <b-sidebar
      id="sidebar-note"
      ref="sideBarNote"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      no-close-on-backdrop
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
            Enregistrer une note
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <validation-observer ref="NoteValidations">
          <b-form
            ref="addNoteForm"
            autocomplete="off"
            class="p-2"
          >
            <b-overlay
              :show="showLoading"
              no-wrap
            />

            <b-form-group
              label="Courtier *"
              label-for="Courtier"
            >
              <validation-provider
                #default="{ errors }"
                name="Courtier"
                rules="required"
              >
                <v-select
                  v-model="note.courtier_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  input-id="id"
                  :reduce="note => note.id"
                  label="courtierName"
                  :options="courtierList"
                  :state="errors.length > 0 ? false : null"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Note *"
              label-for="note"
            >
              <validation-provider
                #default="{ errors }"
                name="Note"
                rules="required"
              >
                <quill-editor
                  id="note"
                  v-model="note.note"
                  :options="snowOption"
                  :state="errors.length > 0 ? false : null"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mr-2"
                type="submit"
                variant="primary"
                @click.prevent="addNote"
              >
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>

    <b-overlay
      :show="showLoading"
      no-wrap
    />
    <div class="d-flex justify-content-between flex-wrap">
      <div class="d-flex align-items-center mb-1 mt-1">
        <b-button
          v-b-toggle.sidebar-note
          class="ml-1"
          size="md"
          variant="primary"
          @click="showAddNote"
        >
          <feather-icon icon="PlusIcon" />
          <span class="align-middle text-uppercase"> Ajouter une note</span>
        </b-button>
      </div>
    </div>
    <b-list-group class="notes_list">
      <template v-if="notes.length > 0">
        <b-list-group-item
          v-for="(note, index) in paginatedItems"
          :key="index"
        >
          <b-row>
            <b-col
              cols="8"
            ><p>
              Note rédigée par <span class="font-weight-bolder"> {{ note.createdBy }} </span> pour le courtier <span class="font-weight-bolder">{{ note.courtierName }}</span>
            </p></b-col>
            <b-col
              cols="4"
              class="text-right"
            >
              <span
                style="cursor: pointer"
                @click="deleteNote(note.id)"
              ><feather-icon
                size="20"
                icon="TrashIcon"
              /></span>
              <span
                v-b-toggle.sidebar-note
                style="cursor: pointer"
                @click="editNote(note)"
              ><feather-icon
                style="cursor: pointer"
                size="20"
                icon="EditIcon"
              /></span>
            </b-col>
          </b-row>
          <p v-html="note.note" />
          <small class="font-weight-bolder">{{ note.date }}</small>
        </b-list-group-item>
      </template>
      <template v-else>
        <b-list-group-item class="text-center pt-3 pb-3">
          <h6 class="font-weight-bolder">
            Aucune note disponible
          </h6>
        </b-list-group-item>
      </template>
    </b-list-group>
    <b-pagination
        v-model="currentPage"
        :total-rows="notes.length"
        :per-page="perPage"
        align="center"
        size="md"
        class="mt-3"
    ></b-pagination>
  </div>
</template>
<script>
import {
  BOverlay,
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BRow,
  BSidebar,
  VBToggle,
  BFormInvalidFeedback,
  BPagination
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  components: {
    quillEditor,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BListGroup,
    BListGroupItem,
    BForm,
    BFormGroup,
    BSidebar,
    BFormInvalidFeedback,
    vSelect,
    BPagination,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      required,
      items: [], // Your original array of items
      perPage: 5, // Number of items per page
      currentPage: 1, // Current page number
      showLoading: false,
      currentUser: JSON.parse(localStorage.getItem('userData')),
      notes: [],
      note: {
        id: null,
        note: null,
        courtier_id: null,
      },
      courtierList: [],
      snowOption: {
        theme: 'snow',
        placeholder: 'Écrivez une note...',
      },
    }
  },
  computed: {
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.notes.slice(startIndex, endIndex);
    },
  },
  created() {
    this.fetchNotesByCourtier()
    this.fetchListCourtier()
  },
  methods: {
    fetchNotesByCourtier() {
      this.showLoading = true
      this.notes = []
      this.$http
        .get('/note/fetchNotePrelevement')
        .then(res => {
          if (res.data.success) {
            this.notes = res.data.data
            this.showLoading = false
            this.$emit('count', this.notes.length)
          }
        })
        .catch(err => {
          this.showLoading = false
          console.error(err)
        })
    },
    fetchListCourtier() {
      this.showLoading = true
      this.notes = []
      this.$http
        .get('/courtier/fetchListCourtier')
        .then(res => {
          if (res.data.success) {
            this.courtierList = res.data.data
            this.showLoading = false
          }
        })
        .catch(() => {
          this.courtierList = []
          this.showLoading = false
        })
    },
    addNote() {
      this.$refs.NoteValidations.validate().then(success => {
        if (success) {
          this.showLoading = true
          this.$http
            .post('/note/actionNewNoteCourtierPrelevement', this.note)
            .then(res => {
              console.log(res.data.data)
              if (res.data.success) {
                this.$refs.sideBarNote.hide()
                if (res.data.data.isCreated) {
                  this.note.id = null
                  this.note.note = null
                  this.note.courtier_id = null
                  this.$refs.NoteValidations.reset()
                  this.notes.unshift(res.data.data)
                } else {
                  this.$refs.NoteValidations.reset()
                  const index = this.notes.findIndex(note => note.id === res.data.data.id)
                  this.notes[index].note = res.data.data.note
                  this.notes[index].courtierName = res.data.data.courtierName
                  this.notes[index].CourtierId = res.data.data.CourtierId

                  this.note.id = null
                  this.note.note = null
                  this.note.courtier_id = null
                }
                this.showLoading = false
                this.$emit('count', this.notes.length)
                this.messageToast(res.data.message, 'Succès', 'success')
              }
            })
            .catch(err => {
              this.showLoading = false
              this.messageToast(err.response.data.errors[0], 'Erreur', 'danger')
              console.log(err)
            })
        }
      })
    },
    showAddNote() {
      this.note.id = null
      this.note.note = null
      this.note.courtier_id = null
    },
    editNote(note) {
      this.note.id = note.id
      this.note.note = note.note
      this.note.courtier_id = note.CourtierId
    },
    deleteNote(id) {
      this.$swal({
        title: 'Etes-vous sûr de vouloir supprimer cette note ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http
            .delete(`note/deleteNoteCourtier/${id}`)
            .then(res => {
              if (res.data.success) {
                this.notes.forEach((note, index) => {
                  if (note.id === id) {
                    this.notes.splice(index, 1)
                    this.$emit('count', this.notes.length)
                  }
                })
              }
              this.messageToast(res.data.message, 'Succès', 'success')
            })
            .catch(error => {
              console.log(error)
              this.messageToast(error.response.data, 'Erreur', 'danger')
            })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
.notes_list {
  margin-bottom: 1rem;
}

.notes_list.list-group .list-group-item:nth-child(even) {
  background-color: #f4f7fc;
}

.notes_list svg {
  float: right;
}
</style>
