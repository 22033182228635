<template>
  <div>
    <b-tabs content-class="mt-3" @input="$emit('handle-tab-change', true)">
      <b-tab title="Prochain Prélèvement" active>
        <prochain-prelevement :prop-child-prochain-prelevement="prochainPrelevement" @visualiser-from-prochain-prelevement="visualiserFromProchainPrelevement"/>
      </b-tab>
      <b-tab title="Prélèvements Executés">
        <prelevement-executes :prop-child-prelevement-executes="prelevementExecutes" @visualiser-from-prelevement-executes="visualiserFromPrelevementExecutes"/>
      </b-tab>
      <b-tab title="Rejets">
        <rejets :prop-child-rejet-prelevement="rejetPrelevement" @visualiser-from-rejet-prelevement="visualiserFromRejetPrelevement"/>
      </b-tab>
      <b-tab title="Notes">
        <notes/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import prochainPrelevement from './prochain-prelevement/prochainPrelevement.vue'
import prelevementExecutes from './prelevement-executes/prelevementExecutes.vue'
import rejets from './rejets/rejets.vue'
import notes from './notes/notes.vue'

export default {
  components: {
    // BTV
    BTabs,
    BTab,

    // UI
    prochainPrelevement,
    prelevementExecutes,
    rejets,
    notes,
  },
  props: {
    propParentPrelevementExecutes: {
      type: Array,
      required: false
    },
    propParentProchainPrelevement: {
      type: Array,
      required: false
    },
    propParentRejetPrelevement: {
      type: Array,
      required: false
    },
  },
  data() {
    return {
      prelevementExecutes: [],
      prochainPrelevement: [],
      rejetPrelevement: [],
    }
  },
  watch: {
    propParentPrelevementExecutes: {
      immediate: true,
      deep: true,
      handler(val) {
        this.prelevementExecutes = val
      }
    },
    propParentProchainPrelevement: {
      immediate: true,
      deep: true,
      handler(val) {
        this.prochainPrelevement = val
      }
    },
    propParentRejetPrelevement: {
      immediate: true,
      deep: true,
      handler(val) {
        this.rejetPrelevement = val
      }
    },
  },
  methods: {
    visualiserFromPrelevementExecutes(data) {
      this.$emit('visualiser-from-prelevement-executes', data)
    },
    visualiserFromProchainPrelevement(data) {
      this.$emit('visualiser-from-prochain-prelevement', data)
    },
    visualiserFromRejetPrelevement(data) {
      this.$emit('visualiser-from-rejet-prelevement', data)
    },
  }
}
</script>