<template>

  <div>
    <b-overlay
      :show="showLoading"
      no-wrap
    />
    <div class="d-flex justify-content-end">
      <b-button
        variant="primary"
        size="md"
        @click="showModal"
      >
        <feather-icon icon="UploadCloudIcon" />
        <span class="align-middle">Import fichier rejets</span>
      </b-button>
    </div>
    <b-modal
      id="gestion-impayee"
      ref="gestion-impayee"
      size="lg"
      title="Importer gestion automatique des impayés "
      :no-close-on-backdrop="true"
      centered
      @hide="clearDataExcel"
    >
      <div class="px-1 d-flex bd-highlight">
        <div class="w-100 bd-highlight">
          <b-form-group
              class="pt-1"
            label="Document d'échéance *"
            label-for="h-type"
            @click="openFileExel"
          >
            <b-input-group>
              <b-input-group-prepend>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover="'Charger un ficher'"
                  class="btn-icon"
                  variant="outline-primary"
                  @click="openFileExel"
                >
                  <b-form-file
                    id="fileClientExel"
                    v-model="fileClientExel"
                    type="file"
                    class="d-none"
                    accept=".xlsx, .xls ,.xlsm,.xlsb,.xltx"
                  />
                  <feather-icon icon="UploadCloudIcon" />
                </b-button>
              </b-input-group-prepend>
              <b-form-input
                disabled
                placeholder="Importer votre base clients/prospects"
                :value="fileClientExel ? fileClientExel.name : ''"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
              class="pt-1"
            label="Date d'échéance *"
            label-for="date"
          >
            <cleave
              id="date"
              v-model="date"
              class="form-control"
              :raw="false"
              :options="options.date"
              placeholder="mois/date"
            />
          </b-form-group>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              :disabled="validation"
              variant="primary"
              style="font-size:12px"
              @click="recordDocumentExcel"
          >
            Importer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BOverlay,
  VBTooltip,
  BFormFile,
  BModal,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  VBToggle,
  BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BButton,
    Cleave,
    BOverlay,
    BFormFile,
    BInputGroupPrepend,
    BModal,
    BFormGroup,
    BInputGroup,
    BFormInput,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      fileClientExel: null,
      validation: true,
      showLoading: false,
      date: '',
      year: '',
      month: '',
      options: {
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['m', 'Y'],
        },
      },

    }
  },
  watch: {
    fileClientExel() {
      this.validationCheck()
    },
    date() {
      this.validationCheck()
    },
  },
  methods: {
    validationCheck() {
      this.validation = true
      const mois = this.date.charAt(0) + this.date.charAt(1)
      const year = this.date.charAt(3) + this.date.charAt(4) + this.date.charAt(5) + this.date.charAt(6)

      this.year = year
      this.month = mois

      if (this.date.length === 2) {
        this.date += '/'
      }
      if ((mois >= 1 && mois <= 12) && (year >= 1920 && year <= 2100)) {
        if (this.date.length < 7 && this.date.length > 7) {
          if (this.fileClientExel == null) { this.validation = true }
        } else if (this.fileClientExel != null) this.validation = false
      } else if (this.fileClientExel == null) this.validation = true
    },
    showModal() {
      this.$refs['gestion-impayee'].show()
    },
    clearDataExcel() {
      this.fileClientExel = null
      this.valdiation = true
      this.date = ''
      this.year = ''
      this.month = ''
    },
    recordDocumentExcel() {
      this.showLoading = true
      const formData = new FormData()
      formData.append('year', this.year)
      formData.append('month', this.month)
      formData.append('file', this.fileClientExel)

      const config = { headers: { 'Content-Type': 'multipart/form-data' } }
      this.$http
        .post('/document/importGestinAutoPayee', formData, config)
        .then(res => {
          if (res.data.success) {
            this.showLoading = false
            this.$refs['gestion-impayee'].hide()
            this.clearDataExcel()
            this.messageToast('Import bien passe', 'Succès', 'success')
          } else {
            this.showLoading = false
            this.messageToast("Import n'a pas bien passe", 'Erreur', 'error')
          }
        })
        .catch(err => {
          this.showLoading = false
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          console.error(err)
        })
    },
    openFileExel() {
      document.getElementById('fileClientExel').click()
    },
  },
}
</script>
